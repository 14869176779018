<template>
	<el-checkbox-group size="small" v-model="allStatus">
		<!-- <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button> -->
    <div>  
      <el-checkbox-button v-for="(item, index) in options.slice(0, 5)" :key="item.value" :label="item.value" @change="changeValue">  
        {{ item.label }}  
      </el-checkbox-button>  
    </div>  
    <div>  
      <el-checkbox-button v-for="(item, index) in options.slice(5, 8)" :key="item.value" :label="item.value" @change="changeValue">  
        {{ item.label }}  
      </el-checkbox-button>  
    </div>  
    <div>  
      <el-checkbox-button v-for="(item, index) in options.slice(8, 12)" :key="item.value" :label="item.value" @change="changeValue">  
        {{ item.label }}  
      </el-checkbox-button>  
    </div>  
    <div>  
      <el-checkbox-button v-for="(item, index) in options.slice(12, 14)" :key="item.value" :label="item.value" @change="changeValue">  
        {{ item.label }}  
      </el-checkbox-button>  
    </div>  
	</el-checkbox-group>
  
</template>

<script>
export default {
  name: 'selectBussinessStatus',
  props:{
		allStatus: ""
	}, 
  data() {
    return {
      options: [
     
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-4",
          label: '申报已提交,等待回执',
        },
        {
          value: "business-5",
          label: '申报成功,待缴款',
        },
        {
          value: "business-6",
          label: '申报成功,已缴款',
        },
        
        {
          value: "business-2",
          label: '申报成功,无需扣款',
        },
        {
          value: "business-21",
          label: '已申报过,待缴款',
        },
        {
          value: "business-22",
          label: '已申报过,已缴款',
        },
        {
          value: "business-20",
          label: '已申报过,无需扣款',
        },
        {
          value: "business-23",
          label: '已申报过,但税额不符',
        },
        {
          value: "business-3",
          label: '申报失败',
        },
        {
          value: "business-40",
          label: '无需申报',
        },
      ],
    };
  },

  mounted() {
    
  },

  methods: {
    changeValue(value) {
      this.$emit('update:allStatus', this.allStatus)
      this.$emit("success",this.allStatus)
    },   
  },
};
</script>

<style lang="scss" scoped>

</style>